<template>
    <div>
        <el-table :data="tableData" class="main-table" header-row-class-name="table-header"
                  cell-class-name="table-hover" :row-class-name="tableRowClassName"
                  @sort-change="handleSort">
            <el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
            </el-table-column>
            <el-table-column prop="product.buyCode" label="订货备件代码"></el-table-column>
            <el-table-column prop="product.name" label="备件名称"></el-table-column>
            <el-table-column prop="product.supplierName" label="供应商"></el-table-column>
            <el-table-column prop="product.supplier" label="供应商代码" align="center"></el-table-column>
            <el-table-column prop="modifyTime" label="录入时间" sortable="custom" align="center"></el-table-column>
            <el-table-column prop="user" label="录入人员" align="center"></el-table-column>
            <el-table-column prop="verifyUser" label="审核人员" align="center"></el-table-column>
            <el-table-column prop="status" label="审核状态">
                <template slot-scope="scope">
                    <el-tag type="success" plain disable-transitions v-if="scope.row.status === 10">通过</el-tag>
                    <el-tag type="warning" plain disable-transitions v-if="scope.row.status === -10">不通过</el-tag>
                    <el-tag type="warning" plain disable-transitions v-if="scope.row.status === -1">审核超时</el-tag>
                    <el-tag disable-transitions v-if="scope.row.status === 2">未审核</el-tag>
                    <el-tag disable-transitions v-if="scope.row.status === 1">未录入</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="120" align="center" class-name="table-operate">
                <template slot-scope="scope">
                    <el-button class="operation-btn" size="small" title="审核信息" type="warning"
                               @click="editClick(scope.row)">审核信息</el-button>
                    <!-- <el-button type="danger" icon="el-icon-delete" circle size="small" title="删除"
                        @click="deleteClick(scope.row)"></el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
                       :page-size="pagesize" @current-change="pageChange">
        </el-pagination>

    </div>
</template>

<script>
export default {
    name: "verifyList",
    props: ['tableData', 'total', 'type', 'pagesize'],
    data() {
        return {
            query: {
                page: 1,
            }
        }
    },
    methods: {
        handleSort(e){
            this.$emit('onSortChange', e)
        },
        pageChange(page){
            this.query.page = page
            this.$emit('pageChange', page)
        },
        tableRowClassName({
                              rowIndex
                          }) {
            if (rowIndex % 2 == 0) {
                return "";
            } else {
                return "active-row";
            }
        },
        indexMethod(e){
            return (this.query.page - 1)*this.pagesize + e + 1
        },
        editClick(item) {
            const detail = this.$router.resolve({name: 'sparesDetails', query: {id: item.id}})
            window.open(detail.href,'_blank')
        },
    }
}
</script>

<style scoped>

</style>
